


.overlay-backdrop {

}

.overlay-panel {
    position: relative;
    
}

.overlay-pane-background {
  width: 100% !important;
  height: 100% !important;
  margin: 0px;
  padding: 0px;
  display: block;
  align-items: center;
  justify-content: center;
  z-index:-1000;
  overflow: scroll;
  content: "Loading"
}

.overlay-pane {
  pointer-events:auto;
  box-sizing:border-box;
  background-color: white;
  border-radius: 24px;
  width: 100%;
  height: 400px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.overlay-loading-pane {
  pointer-events:auto;
  box-sizing:border-box;
  background-color: white;
  border-radius: 24px;
  width: 100%;
  height: 400px;
  opacity: 0
}

@media (min-height: 458px) {
    .overlay-pane, .overlay-pane-sm {
        width: 400px;  //container
        // height: 324px;  //container
    }
}

@media (min-height: 492px) {
    .overlay-pane, .overlay-pane-sm {
        width: 450px;  //container
        // height: 324px;  //container
    }
}

@media (min-width: 576px) {
    .overlay-pane, .overlay-pane-sm {
        width: 500px;  //container
        // height: 324px;  //container
    }
}
@media (min-width: 768px) {
    .overlay-pane, .overlay-pane-md, .overlay-pane-sm {
        width: 720px;  //container
        // height: 406px;
    }
}
@media (min-width: 992px) {
    .overlay-pane, .overlay-pane-lg, .overlay-pane-md, .overlay-pane-sm {
        width: 960px;  //container
        // height: 540px;
    }
}
@media (min-width: 1200px) {
    .overlay-pane, .overlay-pane-lg, .overlay-pane-md, .overlay-pane-sm, .overlay-pane-xl {
        width: 1140px;  //container
        // height: 640px;
    }
}

@media (min-height: 576px) { .overlay-pane, .overlay-pane-sm { height: 576px; }}
@media only screen and (min-height: 768px)  { .overlay-pane, .overlay-pane-md, .overlay-pane-sm { height: 720px;}}
@media only screen and (min-height: 992px)  { .overlay-pane, .overlay-pane-lg, .overlay-pane-md, .overlay-pane-sm {height: 960px; }}
@media only screen and (min-height: 1200px) { .overlay-pane, .overlay-pane-lg, .overlay-pane-md, .overlay-pane-sm, .overlay-pane-xl {height: 1140px;}}


.overlay-fullscreen {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
  display:  flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease 0s;
}

