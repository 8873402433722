@font-face {
  font-family: 'Lora-Bold';
  src: url(Lora-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Lora';
  src: url(Lora-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Archivo-Light';
  src: url(Archivo-Light.ttf) format("truetype");
}

@font-face {
  font-family: 'Archivo';
  src: url(Archivo-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Archivo-SemiBold';
  src: url(Archivo-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: 'Archivo-Bold';
  src: url(Archivo-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Archivo-SemiBoldItalic';
  src: url(Archivo-SemiBoldItalic.ttf) format("truetype");
}


@font-face {
  font-family: 'Miso';
  src: url(Miso.otf) format("opentype");
}

@font-face {
  font-family: 'TrashHand';
  src: url(TrashHand.ttf) format("truetype");
}


@font-face {
  font-family: 'Poetsenone';
  src: url(Poetsenone.otf) format("opentype");
}


@font-face {
  font-family: 'GoodFoot';
  src: url(GoodFoot.otf) format("opentype");
}

@font-face {
  font-family: 'Sixcaps';
  src: url(Sixcaps.ttf) format("opentype");
}

@font-face {
  font-family: 'Serif6';
  src: url(Serif6.otf) format("opentype");
}

@font-face {
  font-family: 'Montserrat';
  src: url(Montserrat.ttf) format("opentype");
}

@font-face {
  font-family: 'Lekton';
  src: url(Lekton.ttf) format("opentype");
}


