/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;600;700&family=Lora:wght@400;700&display=swap');
@import 'overlay.scss';
@import "../node_modules/bootstrap/scss/bootstrap.scss";


// @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
// @media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
// @media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }

html, body { 
  height: 100%; 
  background: var(--bg-color);
}
body { margin: 0; font-family: Archivo, sans-serif; 
  background: var(--bg-color);}

a { color: var(--accent-color);}

h2 {
  font-family: Lora-Bold;
}

:root {

  --modal-radius : 2em;

  --fg-color: #1A0C0D;
  --fg-light-color: #544D4E;
  --fg-lighter-color: #AFAAAB;
  --primary-a-color: #E6E1E1;
  --bg-color: #ffffff;
  --bg-color-light: #f6f6f6;
  --bg-color-landing: #F7F7F7;
  --bg-color-header: #ffffff;
  --bg-color-hover: #E6E1E1;
  --accent-color: #EA475D;

  --message-high-color: #EA475D;
  --message-low-color: #4CA0B6;
  --message-message-color: #53A551;

  --nav-bar-height: 42px;
  --nav-bar-btn-size: 32px;

  --btn-height: 36px;
  --btn-height-small: 24px;
  --txt-size: 9px;
  --question-txt-size: 16px;
  --question-txt-size-small: 12px;

  --question-txt-size-small: 12px;
  --podium-idea-size: 30vh;
  --podium-idea-rank-size: 4vh;
  --podium-card-radius: calc(var(--podium-idea-size)/20.0);
  
}

@media (min-height: 458px) {
    :root {

      --nav-bar-height: 42px;
      --nav-bar-btn-size: 32px;

      --btn-height: 36px;
      --btn-height-small: 24px;
      --txt-size: 9px;
      --question-txt-size: 16px;
      --question-txt-size: 12px;

    }
}

@media (min-height: 492px) {
    :root {

      --nav-bar-height: 42px;
      --nav-bar-btn-size: 32px;

      --btn-height: 36px;
      --btn-height-small: 24px;
      --txt-size: 9px;
      --question-txt-size: 16px;
      --question-txt-size: 12px;
      
    }
}


@media (min-height: 576px) {
    :root {

      --nav-bar-height: 52px;
      --nav-bar-btn-size: 36px;

      --btn-height: 42px;
      --btn-height-small: 30px;
      --txt-size: 14px;
      --question-txt-size: 22px;
      --question-txt-size: 16px;

    }
}

@media (min-height: 640px) {
    :root {

      --nav-bar-height: 52px;
      --nav-bar-btn-size: 36px;

      --btn-height: 42px;
      --btn-height-small: 30px;
      --txt-size: 14px;
      --question-txt-size: 22px;
      --question-txt-size: 16px;

      // --podium-idea-width: 214px;
      // --podium-idea-height: 252px;
      // --podium-idea-size: 252px;
      // --podium-idea-header-height: 30px;
      // --podium-idea-header-top-margin: 8px;
      // --podium-idea-rank-size: 31px;
    }
}






.center-container {
	display: flex;
	align-items: center;
  	justify-content: center;
  	text-align: center;
}


.separator {
  
  width: 100%;
  left: 0;
  right: 0;
	height: 0.6px;
	background-color: rgba(0,0,0,0.25);
}

.separator-light {
	width: 100%;
	height: 0.6px;
	background-color: rgba(255,255,255,0.25);
}


.btn-flat-dark {
  /*width: 80%;*/
  height: var(--btn-height);
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 14px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: calc(var(--btn-height)/2.0);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.btn-flat-dark:hover {
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
  /*color: #fff;*/
  transform: translateY(-1px);
}


.btn-flat-white {
  height: var(--btn-height);
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 14px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: var(--fg-color);
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: calc(var(--btn-height)/2.0);
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.btn-flat-white:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  /*color: #fff;*/
  transform: translateY(-1px);
}




.btn-wire-accent-small {
  height: var(--btn-height-small);
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 13px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: var(--accent-color);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--accent-color);
  border-radius: calc(var(--btn-height)/2.0);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.btn-wire-accent-small:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 15px rgba(168, 44, 59, 0.3);
  /*color: #fff;*/
  transform: translateY(-1px);
}

.btn-wire-accent-small:disabled {
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.1);
  border: 1px solid rgba(168, 168, 168, 0.1);
  color: var(--fg-lighter-color);
}


.btn-wire-accent {
  height: var(--btn-height);
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  font-family: Archivo;
  font-weight: bold;
  font-size: small;
  color: var(--accent-color);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--accent-color);
  border-radius: 45px;
  // box-shadow: 0px 8px 15px rgba(234, 71, 93, 0.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.btn-wire-accent:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 15px rgba(168, 44, 59, 0.3);
  /*color: #fff;*/
  transform: translateY(-1px);
}

.btn-wire-accent:disabled {
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.1);
  border: 1px solid rgba(168, 168, 168, 0.1);
  color: var(--fg-lighter-color);
}


.btn-wire-white {
  height: var(--btn-height);
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 13px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: white;
  border: 1px solid white;
  border-radius: calc(var(--btn-height)/2.0);
  background: transparent;
  // box-shadow: 0px 8px 15px rgba(234, 71, 93, 0.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-wire-white:hover {
  background-color: rgba(255, 255, 255, -.1);
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);
  /*color: #fff;*/
  transform: translateY(-1px);
}

.btn-wire-white:disabled {
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.1);
  border: 1px solid rgba(168, 168, 168, 0.1);
  color: var(--fg-lighter-color);
}

.btn-wire {
  height: var(--btn-height);
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  font-family: Archivo;
  font-weight: bold;
  font-size: 13px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: var(--fg-color);
  border: 1px solid var(--fg-color);;
  border-radius: calc(var(--btn-height) /  2.0);
  background: transparent;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-wire:hover {
  background-color: rgba(255, 255, 255, -.1);
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.2);
  /*color: #fff;*/
  transform: translateY(-1px);
}

.btn-wire:disabled {
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.1);
  border: 1px solid rgba(168, 168, 168, 0.1);
  color: var(--fg-lighter-color);
}

.btn-flat-light {
  height: var(--btn-height);
  padding-left: 45px;
  padding-right: 45px;
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 14px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-flat-light:hover {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.15);
  /*color: #fff;*/
  transform: translateY(-1px);
}

.btn-flat-white {
  height: var(--btn-height);
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: #000;
  background-color: white;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-flat-light:hover {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.15);
  /*color: #fff;*/
  transform: translateY(-1px);
}


.btn-flat-accent {
  height: var(--btn-height);
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 14px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: #FFF;
  background-color: #EA475D;
  border: none;
  border-radius: calc(var(--btn-height)/2.0);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 8px 15px rgba(234, 71, 93, 0.1);
}

.btn-flat-accent:hover {
  background-color: #E04155;
  box-shadow: 0px 8px 15px rgba(168, 44, 59, 0.3);
  /*color: #fff;*/
  transform: translateY(-1px);
}
.btn-flat-accent:disabled {
  background-color: rgba(168, 168, 168, 0.1);
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.1);
  transform: translateY(1px);
}


.btn-flat-accent-small {
  height: var(--btn-height-small);
  padding-left: var(--btn-height-small);
  padding-right: var(--btn-height-small);
  max-width: 100%;
  font-family: Archivo;
  font-weight: 600;
  font-size: 13px;
  /*letter-spacing: 2.5px;*/
  font-weight: 500;
  color: #FFF;
  background-color: #EA475D;
  border: none;
  border-radius: var(--btn-height-small);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 8px 15px rgba(234, 71, 93, 0.1);
}

.btn-flat-accent-small:hover {
  background-color: #E04155;
  box-shadow: 0px 8px 15px rgba(168, 44, 59, 0.3);
  /*color: #fff;*/
  transform: translateY(-1px);
}

.btn-flat-accent-small:disabled {
  background-color: rgba(168, 168, 168, 0.3);
  color: var(--fg-lighter-color);
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.2);
  transform: translateY(1px);
}



.icon-btn {

	width: var(--btn-height);
	height: var(--btn-height);
	padding: 0;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;

	&:hover {
		background-color: rgba(180, 180, 180, 0.2);
		box-shadow: 0px 8px 15px rgba(180, 180, 180, 0.10);
		/*color: #fff;*/
		transform: translateY(-1px);
	}

  &.disabled {
    opacity: 0.3;
  }
}





.dropdown-wire-light-btn {
	height: var(--btn-height);
	padding-left: 1em;
	padding-right: 1em;
  font-family: Archivo;
  font-weight: 600;
	font-size: 14px;
	font-weight: 500;
	color: var(--bg-color);
	background-color: transparent;
	border: 1px solid white;
	border-radius: 45px;
	transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
	cursor: pointer;
	outline: none;
	box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.05);
}

.dropdown-wire-light-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
  // transform: translateY(-1px);
}


.dropdown-wire-light-pannel {
	background-color: #FFF;
	border: none;
	border-radius: 22px;

	transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
	cursor: pointer;
	outline: none;
	box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);

	button {
		background-color: transparent;
		padding: 1em;
	}

	button:hover {
		background-color: transparent;
		padding: 1em;
		font-weight: 500;
	}
}

.img-btn {
  /*width: 80%;*/
  background-color: transparent;
  border: none;
  border-radius: 100%;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.img-btn:hover {
  background-color: rgba(168, 168, 168, 0.3);
  box-shadow: 0px 8px 15px rgba(168, 168, 168, 0.3);
  /*color: #fff;*/
  transform: translateY(-1px);
}








.card-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: none;
  gap: 1em; 

  .card-layout-header { 
    flex-shrink: 1;    
  }

  .card-layout-body { 
    flex-grow: 1;
  }
  .card-layout-footer { 
    flex-shrink: 1; 
  }

}



/////////////////////////////////////////    
/////////////////////////////////////////   Stats
///////////////////////////////////////// 
/// 
/// 
.stats-container {
  display: flex;
  flex-direction: row;
  align-items: top;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 1em;
  justify-content: space-around;
} 

.stats-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;

  .stats-count-date {
      display:inline;
      padding : 0;
      font-family: Archivo;
      font-size: 1.8rem;
      line-height: 1.5rem;
  }

  .stats-count-value{ 
      display:inline;
      padding : 0;
      font-family: Archivo;
      font-size: 1.8rem;
      line-height: 1.5rem;
  }
  .stats-count-label{ 
      display:inline;
      padding : 0;
      font-family: Archivo;
      color: var(--fg-color);
// color: var(--fg-lighter-color);
// color: var(--accent-color);
      font-family: Archivo-SemiBold;
      font-size: small;
  }
}

/////////////////////////////////////////    
/////////////////////////////////////////    Dropdown
///////////////////////////////////////// 



.toguna_dropdown_item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--accent-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.toguna_dropdown {
  
}


/////////////////////////////////////////    
/////////////////////////////////////////    Modal popups
/////////////////////////////////////////    

.popup {
  justify-self: stretch;
  align-self: stretch;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.popup-title {
  font-size: 1.5rem;
  font-family: Lora-Bold;
}

.popup-text {
  font-size: 1rem;
}

.popup-header {
  border: none;
  min-height: 72px;
  padding: 24px;
  flex-shrink: 1; 
}

.popup-body {
  display: flex;
  border: none;
  padding: 24px;
  flex-direction: column;
  align-self:stretch;
  flex-grow: 1; 
}

.popup-footer {
  min-height: 72px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: end;
  border: none;
  gap: 1em;
  flex-shrink: 1; 

  .popup-footer-items {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
  }

}

.modal-backdrop {

}

.xl-modal {
  @extend .modal;

  border-radius: var(--modal-radius);
  border: none;
  outline: none;
  .modal-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;
    @extend .modal-xl;
  
    border-radius: inherit;
    border: none;
    background-color: #000;
    .modal-content {
        position: absolute;
        top: 0;
        bottom: 0;
        border-radius: inherit;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        background-color: #000;
    }
  }
}


  
.rules-modal {
  @extend .modal;

  border-radius: var(--modal-radius);
  border: none;
  height: 100%;
  outline: none;

  .modal-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;
    // @extend .modal-xl;
  
    // margin: 0;
    // height: 100%;
    
    // height: 100%;
        position: relative;
    border-radius: inherit;
    border: none;
    background-color: #FFF;

    .modal-content {
        position: absolute;
        top: 0;
        bottom: 0;
        border-radius: inherit;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        background-color: #FFF;
    }
  }
}


@media (max-height: 576px) { 

  .xl-modal {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  
    .modal-dialog {
        max-width: 100%;
        margin: 0;
        height: 100%;
        border-radius: 0;
       
        .modal-content {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0;
        }
     }
  }
}

@include media-breakpoint-down(lg) { 
  .xl-modal {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  
    .modal-dialog {
        max-width: 100%;
        margin: 0;
        height: 100%;
        border-radius: 0;
       
        .modal-content {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0;
        }
     }
  }
}

.default-modal {
  border-radius: var(--modal-radius);
  border: none;
  outline: none;
  .modal-dialog {
      border-radius: inherit;
      border: none;
      .modal-content {
          border-radius: inherit;
      }
    }
}




/////////////////////////////////////////    
/////////////////////////////////////////    Nav bar
/////////////////////////////////////////    
.nav-bar {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--nav-bar-height);

  div {
    
  }
}



/////////////////////////////////////////    
/////////////////////////////////////////    Connectç steps
/////////////////////////////////////////    


ul.connect-steps {
	position: relative;
  margin:0;
	padding: 30px;

	li:before {
		content: counter(section);
		position: absolute;
		background-color: var(--accent-color);
		text-align:center;
		color: white;
		font-size: 100%;
		font-family: 'Archivo-Bold';
		border: none;
		width: 24px;
		left: 0px;
		height: 24px;
		border-radius: 50%;
	}
}

ul.connect-steps:nth-child {
}

.connect-separator {
  margin: 2em 0em 4em 0em;
}

ul.connect-steps>li {
	counter-increment: section;
  padding-left: 0px;
  min-height: 52px;
  left: 0px;
	list-style-type: none;
}
